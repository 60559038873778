//D:\ApplicationDevelopmentHillyardGit\Net\B2B\B2BC\Scripts\Custom\app.js
'use strict';
var hillyardAngularApps = angular.module('HillyardAngularApps',
    [
        'dataWarehouseTemplates',
        'angularMoment',
        'applicationMenuModule',
        'userMenuModule',
        'ngSanitize',
        'systemStatusModule',
        'createAccountModule'
    ]);

hillyardAngularApps.value('versionInfo', { ProductUrl: '' });

// TODO: this code clears the template cache for testing
hillyardAngularApps.run(function ($rootScope, $templateCache, $window, versionInfo) {
    var host = $window.location.host;
    if (host === 'b2b.hillyard.com') {
        versionInfo.ProductUrl = 'https://servicesproducts.hillyard.com/api/';
    } else if (host === 'b2bqas.hillyard.com' || host === '') {
        versionInfo.ProductUrl = 'https://servicesproductsdev.hillyard.com/api/';
    } else {
        versionInfo.ProductUrl = 'http://localhost:55901/api/';
    }
    $rootScope.$on('$viewContentLoaded', function () {
        $templateCache.removeAll();
    });
});

hillyardAngularApps.constant('appConfig', { catalog: 'HYONLINE1' });





// String Format for JavaScript "{0} is old, but {1} is a little better".format("ASP","ASP.NET");
if (!String.prototype.format) {
    String.prototype.format = function () {
        var args = arguments;
        return this.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != 'undefined'
              ? args[number]
              : match
            ;
        });
    };
}

// bootstrap tooltip
$(function () {
    $('[data-toggle="tooltip"]').tooltip();
});

// click template update form
$('.btnUpdateForm').click(function () {
    var id = $(this).attr('id');
    $('#UpdateForm-' + id).submit();
});