//D:\ApplicationDevelopmentHillyardGit\Net\B2B\B2BC\Scripts\Custom\services\SearchProviderService.js
hillyardAngularApps.factory('searchProviderService', ['$log', 'httpService', 'sharePointSearchService', 'locationCookieService', 'appConfig',
    function ($log, httpService, sharePointSearchService, locationCookieService, appConfig) {
        var local = this;
        return {
            getCurrentProvider: function (searchProviders) {
                var searchProvider = {
                    SearchFormUrl: '/Scripts/Custom/templates/search/GenericSearchForm.html',
                    SearchResults: [],
                    SearchEngines: [
                    {
                        Name: 'Quick Results',  // Auto Complete
                        AutoComplete: true,
                        VisibleIfNoResults: true,
                        OpenIfResults: true,
                        SearchUrl: 'ProductSearch/CommonWordSearch/?quickSearch=true&catalog={0}&'.format(appConfig.catalog),
                        ResultsForm: 'CommonWordResultForm',
                        SearchFunction: httpService.returnB2BGetAction,
                        DefaultPages: [],
                        CustomSearchParameters: undefined
                    },
                    {
                        Name: 'Quick Results',
                        AutoComplete: false,
                        VisibleIfNoResults: false,
                        OpenIfResults: true,
                        SearchUrl: 'ProductSearch/CommonWordSearch/?quickSearch=false&catalog={0}&'.format(appConfig.catalog),
                        ResultsForm: 'CommonWordResultForm',
                        SearchFunction: httpService.returnB2BGetAction,
                        DefaultPages: [],
                        CustomSearchParameters: undefined
                    },
                    {
                        Name: 'All Products',
                        AutoComplete: false,
                        VisibleIfNoResults: false,
                        OpenIfResults: false,
                        SearchUrl: 'ProductSearch/ProductSearch/?',
                        ResultsForm: 'ProductResultForm',
                        SearchFunction: httpService.returnB2BGetAction,
                        DefaultPages: [],
                        CustomSearchParameters: function () {
                            var searchQuery = '';
                            _.each($('.searchQuery'), function (value) {
                                if ($(value).val().length > 0) {
                                    searchQuery = $(value).val();
                                }
                            });
                            var locationCookie = locationCookieService.readLocationCookie();
                            var distributorId = 0;
                            if (locationCookie != undefined) {
                                var cookieData = JSON.parse(locationCookie);
                                if (cookieData.Type == 'C') {
                                    distributorId = cookieData.Id;
                                } else {
                                    distributorId = 99999;
                                }
                            }
                            return "parameter={0}&distributorId={1}&whatwemake=&catalog={2}".format(searchQuery, distributorId, appConfig.catalog);
                        }
                    },
                    {
                        Name: 'Products At My Location',
                        AutoComplete: false,
                        VisibleIfNoResults: false,
                        OpenIfResults: false,
                        SearchUrl: 'ProductSearch/ProductSearch/?',
                        ResultsForm: 'ProductResultForm',
                        SearchFunction: httpService.returnB2BGetAction,
                        DefaultPages: [],
                        CustomSearchParameters: function () {
                            var searchQuery = '';
                            _.each($('.searchQuery'), function (value) {
                                if ($(value).val().length > 0) {
                                    searchQuery = $(value).val();
                                }
                            });
                            var distributorId = locationCookieService.readHillyardDistributorId();
                            if (distributorId === 0) {
                                distributorId = 99999;
                            }
                            return "parameter={0}&distributorId={1}&whatwemake=&catalog={2}".format(searchQuery, distributorId, appConfig.catalog);
                        }
                    },
                    {
                        Name: 'Hillyard Manufactured',
                        AutoComplete: false,
                        VisibleIfNoResults: false,
                        OpenIfResults: false,
                        SearchUrl: 'ProductSearch/ProductSearch/?',
                        ResultsForm: 'ProductResultForm',
                        SearchFunction: httpService.returnB2BGetAction,
                        DefaultPages: [],
                        CustomSearchParameters: function () {
                            var searchQuery = '';
                            _.each($('.searchQuery'), function (value) {
                                if ($(value).val().length > 0) {
                                    searchQuery = $(value).val();
                                }
                            });
                            var distributorId = locationCookieService.readHillyardDistributorId();
                            return "parameter={0}&distributorId={1}&whatwemake=true&catalog={2}".format(searchQuery, distributorId, appConfig.catalog);
                        }
                    },

                    {
                        Name: 'SDS',
                        AutoComplete: false,
                        VisibleIfNoResults: false,
                        OpenIfResults: false,
                        SearchUrl: 'MSDS/MSDSSearch/?catalog={0}&'.format(appConfig.catalog),
                        ResultsForm: 'MSDSReultForm',
                        SearchFunction: httpService.returnGetAction,
                        DefaultPages: [],
                        CustomSearchParameters: undefined
                    },
                    {
                        Name: 'Locations',
                        AutoComplete: false,
                        VisibleIfNoResults: false,
                        OpenIfResults: false,
                        SearchUrl: 'Distributor/SearchSimpleDistributors/?catalog={0}&'.format(appConfig.catalog),
                        ResultsForm: 'LocationResultForm',
                        SearchFunction: httpService.returnGetAction,
                        DefaultPages: [],
                        CustomSearchParameters: undefined
                    },
                    {
                        Name: 'Site Content',
                        AutoComplete: false,
                        VisibleIfNoResults: false,
                        OpenIfResults: false,
                        SearchUrl: '',
                        ResultsForm: 'SharePointResultForm',
                        SearchFunction: sharePointSearchService.sharePontSearch,
                        DefaultPages: [],
                        CustomSearchParameters: function () {
                            var searchQuery = '';
                            _.each($('.searchQuery'), function (value) {
                                if ($(value).val().length > 0) {
                                    searchQuery = $(value).val();
                                }
                            });
                            return searchQuery;
                        }
                    }],
                    SearchParameters: function () {
                        var searchQuery = '';
                        _.each($('.searchQuery'), function (value) {
                            if ($(value).val().length > 0) {
                                searchQuery = $(value).val();
                            }
                        });
                        return "parameter=" + searchQuery;
                    },
                };

                return searchProvider;
            },
        };
    }]);