//D:\ApplicationDevelopmentHillyardGit\Net\B2B\B2BC\Scripts\Custom\controllers\AccountCreateCtrl.js
(function () {
    'use strict';

    angular
        .module('HillyardAngularApps')
        .controller('AccountCreateCtrl', AccountCreateCtrl);

    AccountCreateCtrl.$inject = [];

    function AccountCreateCtrl() {
        var vm = this;
        vm.accountAdded = false;

        vm.afterSaveEvent = function (account) {
            if (account.accountId > 0) {
                vm.accountAdded = true;
            }
        };

    };
})();
