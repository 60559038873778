//D:\ApplicationDevelopmentHillyardGit\Net\B2B\B2BC\Scripts\Custom\controllers\ImInterestedController.js
hillyardAngularApps.controller('ImInterestedCtrl', ['$scope', '$location', '$log', 'httpService', 'appConfig',
    function ($scope, $location, $log, httpService, appConfig) {
        $scope.buttonLabel = "send";
        $scope.imInterestedAlerts = [];
        $scope.formVisible = true;
        $scope.postBackCode = '';
        $scope.formCollapsed = true;
        var source;// = 1; // 1-Hillyard.com, 2-Wood Professional
        if (appConfig.catalog === "HYONLINE1") {
            source = 1;
        } else {
            source = 2;
        }

        $scope.$on('addToggleImInterestedCollapsed', function (event, args) {
            $scope.formCollapsed = args.collapsed;
        });

        $scope.update = function (interested) {
            $scope.hideAlert();
            $scope.validateForm(interested);
            if ($scope.imInterestedAlerts.length === 0) {
                var groupName = $('#imInterestedContainer').attr('data-imInterestedGroupName');
                if (groupName == undefined) {
                    groupName = '';
                }
                var material = $('#imInterestedMaterial').attr('data-iminterestedMaterial');
                if (material == undefined) {
                    material = '';
                }
                var imInterested = {
                    FirstName: interested.firstName,
                    LastName: interested.lastName,
                    EmailAddress: interested.emailAddress,
                    Title: interested.title,
                    Organization: interested.organization,
                    Zip: interested.zip,
                    Comments: interested.commentsQuestions,
                    GroupName: groupName,
                    Url: $location.absUrl(),
                    PostBackCode: $scope.postBackCode,
                    Hillyard: interested.hillyard,
                    Material: material,
                    Source: source
                };
                $scope.buttonLabel = "Sending...";

                httpService.postContactUs("ImInterested", imInterested).then(
                    function (results) {
                        if (results.ResponseCode == 0) {
                            $scope.showAlert('success', 'Thanks for your feedback.');
                            $scope.buttonLabel = "Thanks";
                            $scope.formVisible = false;
                        } else if (results.ResponseCode == 3) {
                            $scope.showAlert('warning', 'The form still has some fields that need completed.  Please correct the following fields and try again.');
                            _.each(results.ValidationErrors, function (element, index, list) {
                                $scope.showAlert('warning', element);
                            });
                        } else {
                            $scope.showAlert('danger', 'The form was not submitted due to an error.  Please try again later.');
                            $scope.buttonLabel = "Send";
                        }
                    },
                    function () {
                        $scope.showAlert('danger', 'The form was not submitted due to a server or network error.  Please try again later.');
                        $scope.buttonLabel = "Send";
                    });
            }
        };

        $scope.validateForm = function (interested) {
            if (interested == undefined) {
                $scope.showAlert("warning", "The form could not be submitted.  Please double check that the form is complete.");
                return;
            }
            if (interested.firstName == undefined || interested.firstName.length <= 2) {
                $scope.showAlert("warning", "First name is required");
            }
            if (interested.lastName == undefined || interested.lastName.length <= 2) {
                $scope.showAlert("warning", "Last name is required");
            }
            if (interested.zip == undefined || interested.zip < 10000) {
                $scope.showAlert("warning", "Zip code is required");
            }
            if (interested.emailAddress == undefined || interested.emailAddress.length <= 5) {
                $scope.showAlert("warning", "Email Address is required");
            }
        };

        $scope.postBackCode = function () {
            httpService.returnGetAction('PostCode/GetPostBackCode').then(
                function (results) {
                    $scope.postBackCode = results;
                },
                function (results) {
                    $log.error(results);
                }
            );
        };

        $scope.expandForm = function () {
            $scope.formCollapsed = !$scope.formCollapsed;
        }

        $scope.showAlert = function (type, message) {
            $scope.imInterestedAlerts.push({ type: type, msg: message });
        };

        $scope.hideAlert = function () {
            $scope.imInterestedAlerts = [];
        };

        $scope.closeAlert = function (index) {
            $scope.imInterestedAlerts.splice(index, 1);
        };

        var isOpen = $('#imInterestedCollapsed').attr('data-imInterestedIsOpen');
        if (isOpen != undefined && isOpen == '1') {
            $scope.formCollapsed = false;
        }

        $scope.postBackCode();
    }]);