//D:\ApplicationDevelopmentHillyardGit\Net\B2B\B2BC\Scripts\Custom\services\AccountActivityCookieService.js
hillyardAngularApps.factory('accountActivityCookieService', ['$log', 'cookieService',
    function ($log, cookieService) {
        var searchParameterCookieName = 'searchParameters';
        return {
            createSearchParameterCookie: function (cookieValue) {
                cookieService.createCookie(searchParameterCookieName, JSON.stringify(cookieValue), 365);
            },
            readSearchParameterCookie: function () {
                var cookie = cookieService.readCookie(searchParameterCookieName);
                if (cookie != undefined) {
                    return JSON.parse(cookie);
                }
            },
            eraseCookie: function () {
                cookieService.eraseCookie(searchParameterCookieName);
            }

        }
    }]);