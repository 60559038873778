//D:\ApplicationDevelopmentHillyardGit\Net\B2B\B2BC\Scripts\Custom\services\SharePointSearchService.js
hillyardAngularApps.factory('sharePointSearchService', ['$q', 'httpService', 'serverUrl',
    function ($q, httpService, serverUrl) {
    var local = this;
    return {
        sharePontSearch: function (parameter) {
            var deferred = $q.defer();
            var url = serverUrl.getSharePointSearchUrl().format(parameter);
            httpService.returnSharePointGetAction(url).then(
                function (results) {
                    var data = [];
                    if (results.d != undefined &&
                        results.d.query != undefined &&
                        results.d.query.PrimaryQueryResult != undefined &&
                        results.d.query.PrimaryQueryResult.RelevantResults != undefined &&
                        results.d.query.PrimaryQueryResult.RelevantResults.Table != undefined &&
                        results.d.query.PrimaryQueryResult.RelevantResults.Table.Rows != undefined &&
                        results.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results != undefined &&
                        results.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results.length > 0) {
                        var rs = results.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
                        angular.forEach(rs, function (value, key) {
                            if (value.Cells != undefined && value.Cells.results != undefined && value.Cells.results.length > 0) {
                                var titleObject = _.find(value.Cells.results, function (obj) { return obj.Key == 'Title'; });
                                var pathObject = _.find(value.Cells.results, function (obj) { return obj.Key == 'Path'; });
                                var hithighlightedSummaryObject = _.find(value.Cells.results, function (obj) { return obj.Key == 'HitHighlightedSummary'; });
                                if (titleObject != undefined && pathObject != undefined &&
                                    titleObject.Value != undefined && titleObject.Value.length > 0 &&
                                    pathObject.Value != undefined && pathObject.Value.length > 0) {
                                    var hitHighlighted = '';
                                    if (hithighlightedSummaryObject != undefined && hithighlightedSummaryObject.Value != undefined && hithighlightedSummaryObject.Value.length > 0) {
                                        hitHighlighted = hithighlightedSummaryObject.Value;
                                        hitHighlighted = hitHighlighted.split('<c0>').join('<span class="highlighted">');
                                        hitHighlighted = hitHighlighted.split('</c0>').join('</span>');
                                        hitHighlighted = hitHighlighted.replace('<ddd/>', '');
                                    }
                                    data.push({ Title: titleObject.Value, Url: pathObject.Value, HitHighlighted: hitHighlighted });
                                }
                            }
                        });
                    }
                    deferred.resolve(data);
                },
                function (results) {
                    deferred.reject(status);
                });

            return deferred.promise;
        },
    };
}]);