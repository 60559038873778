//D:\ApplicationDevelopmentHillyardGit\Net\B2B\B2BC\Scripts\Custom\controllers\TemplateController.js
(function () {
    "use strict";

    angular
        .module("HillyardAngularApps")
        .controller("TemplateController", template);

    template.$inject = ['$http', '$q'];

    function template($http, $q) {
        var vm = this;

        vm.addToTemplate = false;
        vm.editMinMax = false;

        vm.toggleAddToTemplate = toggleAddToTemplate;
        vm.toggleEditMinMax = toggleEditMinMax;

        vm.edit = {
            name: false,
            qtys: false,
            items: []
        };

        vm.template = {
            current: [],
            fullCase: true,
            name: '',
            min: 0,
            max: 0,
            qty: 0,
            type: { value: '', text: 'Select Template Type' },
            types: [
                { value: 'INV', text: 'Invoice' },
                { value: 'ORD', text: 'Order' },
                { value: 'REO', text: 'Reorder' }
            ]
        }

        vm.selectedTemplate = selectedTemplate;

        activate();

        ////////////////////

        function activate() {
            var promises = [];
            return $q.all(promises).then(function () {
                // these are the promises
            });
        }

        function toggleAddToTemplate() {
            vm.addToTemplate = !vm.addToTemplate;
        }

        function toggleEditMinMax() {
            vm.editMinMax = !vm.editMinMax;
        }

        function apiGetTemplates() {
            return $http.get('/myaccountactivity/gettemplates')
                .then(success)
                .catch(fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                var msg = 'Get failed for GetTemplates';
                console.log(error);
                return $q.reject(msg);

            }
        }

        function getTemplates() {
            return apiGetTemplates().then(function (data) {
                vm.template.current = data;
            });
        }

        function selectedTemplate() {
            for (var i = 0; i < vm.template.current.Documents.length; i++) {
                if (vm.template.current.Documents[i].DocumentNumber === vm.template.selected) {
                    vm.template.selectedTemplate = vm.template.current.Documents[i];
                    return;
                }
            }
        }
    }

})();