//D:\ApplicationDevelopmentHillyardGit\Net\B2B\B2BC\Scripts\Custom\services\ServerUrlService.js
hillyardAngularApps.factory('serverUrl', ['appConfig','$window',
    function (appConfig, $window) {
        return {
            getServerUrl: function () {
                var host = $window.location.host;
                if (host.indexOf('localhost') >= 0) {
                    return 'https://localhost:64926/api/';
                } else if (host.indexOf('dev') >= 0 || host.indexOf('test') >= 0 || host.indexOf('qas') >= 0) {
                    return 'https://ServicesDev.Hillyard.com/api/';
                } else {
                    return 'https://services.hillyard.com/api/';
                }
            },
            getB2BServerUrl: function () {
                var host = $window.location.host;
                if (host.indexOf('localhost') >= 0) {
                    return 'http://localhost:49302/api/';
                } else if (host.indexOf('dev') >= 0 || host.indexOf('test') >= 0 || host.indexOf('qas') >= 0) {
                    return 'https://b2bqas.Hillyard.com/api/';
                } else {
                    return 'https://b2b.hillyard.com/api/';
                }
            },
        getApiServerUrl: function () {
            //return 'https://mossfedev2013:9876/';  // Hillyard.com dev
            return 'https://www.hillyard.com:9876/'; // Hillyard.com prod
        },
        getSharePointSearchUrl: function () {
            if (appConfig.catalog === 'HYONLINE1') {
                //return "https://new.hillyard.com/_api/search/query?querytext='{0}'&rowsperpage=0&rowlimit=50";
                //return "https://www.hillyard.com/_api/search/query?querytext='{0}'&rowsperpage=0&rowlimit=50&QueryTemplatePropertiesUrl='spfile://webroot/queryparametertemplate.xml'";
                return "https://wwwdev.hillyard.com/_api/search/query?querytext='{0}'&rowsperpage=0&rowlimit=50&QueryTemplatePropertiesUrl='spfile://webroot/queryparametertemplate.xml'";
                //return "https://mossfedev2013:616/_api/search/query?querytext='{0}'&rowsperpage=0&rowlimit=50";
            } else if (appConfig.catalog === 'WOODPRO') {
                return "https://www.woodprofessional2.com/_api/search/query?querytext='{0}'&rowsperpage=0&rowlimit=50&QueryTemplatePropertiesUrl='spfile://webroot/queryparametertemplate.xml'";
            }
        },
        getSharePointProductDataSheets: function () {
            if (appConfig.catalog === 'HYONLINE1') {
                return "https://www.hillyard.com/MediaCenter/_api/web/getfolderbyserverrelativeurl('Documents/Product%20Data%20Sheets')/files?$select=Name,ServerRelativeUrl&$filter=substringof('{0}',ServerRelativeUrl)";
                //return "https://new.hillyard.com/MediaCenter/_api/web/getfolderbyserverrelativeurl('Documents/Product%20Data%20Sheets')/files?$select=Name,ServerRelativeUrl&$filter=substringof('HIL00110.pdf',ServerRelativeUrl)";
            } else if (appConfig.catalog === 'WOODPRO') {
                return "https://www.woodprofessional2.com/MediaCenter/_api/web/getfolderbyserverrelativeurl('Documents/Product%20Data%20Sheets')/files?$select=Name,ServerRelativeUrl&$filter=substringof('{0}',ServerRelativeUrl)";
            }
        },
        getSharePointRTUs: function () {
            if (appConfig.catalog === 'HYONLINE1') {
                return "https://www.hillyard.com/MediaCenter/_api/web/getfolderbyserverrelativeurl('Documents/RTU')/files?$select=Name,ServerRelativeUrl&$filter=substringof('{0}',ServerRelativeUrl)";
                //return "https://new.hillyard.com/MediaCenter/_api/web/getfolderbyserverrelativeurl('Documents/Product%20Data%20Sheets')/files?$select=Name,ServerRelativeUrl&$filter=substringof('HIL00110.pdf',ServerRelativeUrl)";
            } else if (appConfig.catalog === 'WOODPRO') {
                return "https://www.woodprofessional2.com/MediaCenter/_api/web/getfolderbyserverrelativeurl('Documents/RTU')/files?$select=Name,ServerRelativeUrl&$filter=substringof('{0}',ServerRelativeUrl)";
            }
        },
    };
}]);