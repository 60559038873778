//D:\ApplicationDevelopmentHillyardGit\Net\B2B\B2BC\Scripts\Custom\controllers\ShoppingCartController.js
(function () {
    "use strict";
    
    angular
        .module("HillyardAngularApps")
        .controller("ShoppingCartController", shoppingCart);
    
    shoppingCart.$inject = ['$http', '$q'];

    function shoppingCart($http, $q) {
        var vm = this;
        vm.addToCart = false;

        vm.toggleAddToCart = toggleAddToCart;

        vm.template = {
            current: [],
            fullCase: true,
            name: '',
            min: 0,
            max: 0,
            qty: 0,
            type: { value: '', text: 'Select Template Type' },
            types: [
                { value: 'INV', text: 'Invoice' },
                { value: 'ORD', text: 'Order' },
                { value: 'REO', text: 'Reorder' }
            ]
        }

        vm.selectedTemplate = selectedTemplate;

        vm.templateOfChoice;


        activate();

        ////////////////////

        function activate() {
            var promises = [getTemplates()];
            return $q.all(promises).then(function () {
                // these are the promises
            });
        }

        function toggleAddToCart() {
            vm.addToCart = !vm.addToCart;
        }

        function apiGetTemplates() {
            return $http.get('/myaccountactivity/gettemplates')
                .then(success)
                .catch(fail);

            function success(response) {
                return response.data;
            }

            function fail(error) {
                var msg = 'Get failed for GetTemplates';
                console.log(error);
                return $q.reject(msg);

            }
        }

        function getTemplates() {
            return apiGetTemplates().then(function (data) {
                vm.template.current = data;
            });
        }

        function selectedTemplate() {
//            vm.template.selectedTemplate = "";
            for (var i = 0; i < vm.template.current.Documents.length; i++) {
                if (vm.template.current.Documents[i].DocumentNumber == vm.templateOfChoice) {
                    vm.template.selectedTemplate = vm.template.current.Documents[i];
                }
            }
            return;
        }

        function redirectToTemplates() {
            window.location = "/MyAccountActivity/Index?docType=T";
        }
    }
})();