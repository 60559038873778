//D:\ApplicationDevelopmentHillyardGit\Net\B2B\B2BC\Scripts\Custom\services\HttpService.js
hillyardAngularApps.factory('httpService', ['$rootScope', '$http', '$q', 'serverUrl',
    function ($rootScope, $http, $q, serverUrl) {
    return {
        returnGetAction: function (url) {
            if (url.indexOf('?') >= 0) {
                url = serverUrl.getServerUrl() + url + '&callback=JSON_CALLBACK';
            } else {
                url = serverUrl.getServerUrl() + url + '?callback=JSON_CALLBACK';
            }

            var deferred = $q.defer();
            $rootScope.$broadcast('showLoadingBroadcast', { method: 'returnGetAction', url: url });
            $http({ method: 'JSONP', withCredentials: false, url: url }).
                success(function (data, status, headers, config) {
                    deferred.resolve(data);
                    $rootScope.$broadcast('hideLoadingBroadcast', { method: 'returnGetAction', url: url });
            }).
                error(function (data, status, headers, config) {
                    deferred.reject(status);
                    $rootScope.$broadcast('hideLoadingBroadcast', { method: 'returnGetAction', url: url });
        });
            return deferred.promise;
        },
        returnB2BGetAction: function (url) {
            //if (url.indexOf('?') >= 0) {
            //    url = serverUrl.getB2BServerUrl() + url + '&callback=JSON_CALLBACK';
            //} else {
            //    url = serverUrl.getB2BServerUrl() + url + '?callback=JSON_CALLBACK';
            //}
            url = serverUrl.getB2BServerUrl() + url;

            var deferred = $q.defer();
            $rootScope.$broadcast('showLoadingBroadcast', { method: 'returnGetAction', url: url });
            $http({ method: 'GET', withCredentials: true, url: url }).
                success(function (data, status, headers, config) {
                    deferred.resolve(data);
                    $rootScope.$broadcast('hideLoadingBroadcast', { method: 'returnGetAction', url: url });
                }).
                error(function (data, status, headers, config) {
                    deferred.reject(status);
                    $rootScope.$broadcast('hideLoadingBroadcast', { method: 'returnGetAction', url: url });
                });
            return deferred.promise;
        },
        returnSharePointGetAction: function (url) {
            var deferred = $q.defer();
            $rootScope.$broadcast('showLoadingBroadcast', { method: 'returnSharePointGetAction', url: url });
            $http({ method: 'GET', url: url, headers: { 'Accept': 'application/json;odata=verbose;charset=utf-8' } }).
                success(function (data, status, headers, config) {
                    deferred.resolve(data);
                    $rootScope.$broadcast('hideLoadingBroadcast', { method: 'returnSharePointGetAction', url: url });
                }).
                error(function (data, status, headers, config) {
                    deferred.reject(status);
                    $rootScope.$broadcast('hideLoadingBroadcast', { method: 'returnSharePointGetAction', url: url });
                });
            return deferred.promise;
        },
        returnSharePointDocuments: function (url) {
            var deferred = $q.defer();
            $http({ method: 'GET', url: url, headers: { 'Accept': 'application/json;odata=verbose;charset=utf-8' } }).
                success(function (data, status, headers, config) {
                    deferred.resolve(data);
                }).
                error(function (data, status, headers, config) {
                    deferred.reject(status);
                });
            return deferred.promise;
        },
        postContactUs: function (url, data) {
            url = serverUrl.getServerUrl() + url;
            var deferred = $q.defer();
            $rootScope.$broadcast('showLoadingBroadcast', { method: 'postContactUs', url: url });
            $http({
                url: url,
                method: 'POST',
                data: JSON.stringify(data)
            })
            .success(function (data, status, headers, config) {
                deferred.resolve(data);
                $rootScope.$broadcast('hideLoadingBroadcast', { method: 'postContactUs', url: url });
            })
            .error(function (data, status, headers, config) {
                deferred.reject(status);
                $rootScope.$broadcast('hideLoadingBroadcast', { method: 'postContactUs', url: url });
            });
            return deferred.promise;
        },
        postImInterested: function (url, data) {
            url = serverUrl.getServerUrl() + url;
            var deferred = $q.defer();
            $rootScope.$broadcast('showLoadingBroadcast', { method: 'postImInterested', url: url });
            $http({
                url: url,
                method: 'POST',
                data: JSON.stringify(data)
            })
            .success(function (data, status, headers, config) {
                deferred.resolve(data);
                $rootScope.$broadcast('hideLoadingBroadcast', { method: 'postImInterested', url: url });
            })
            .error(function (data, status, headers, config) {
                deferred.reject(status);
                $rootScope.$broadcast('hideLoadingBroadcast', { method: 'postImInterested', url: url });
            });
            return deferred.promise;
        },
        postAddToCart: function (data) {
            url = serverUrl.getServerUrl() + 'ShoppingCart/AddToCart';
            var deferred = $q.defer();
            $rootScope.$broadcast('showLoadingBroadcast', { method: 'postAddToCart', url: url });
            $http({
                url: url,
                method: 'POST',
                data: JSON.stringify(data)
            })
            .success(function (data, status, headers, config) {
                deferred.resolve(data);
                $rootScope.$broadcast('hideLoadingBroadcast', { method: 'postAddToCart', url: url });
            })
            .error(function (data, status, headers, config) {
                deferred.reject(status);
                $rootScope.$broadcast('hideLoadingBroadcast', { method: 'postAddToCart', url: url });
            });
            return deferred.promise;
        }
    };
}]);