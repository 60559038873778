//D:\ApplicationDevelopmentHillyardGit\Net\B2B\B2BC\Scripts\Custom\controllers\ProductFilterController.js
(function () {
    'use strict';
    angular
        .module('HillyardAngularApps')
        .controller('ProductFilterCtrl', ProductFilterCtrl);

    ProductFilterCtrl.$inject = ['$rootScope', '$scope', '$http', '$window', 'moment', 'versionInfo'];

    function ProductFilterCtrl($rootScope, $scope, $http, $window, moment, versionInfo) {
        var vm = this;
        vm.productTypeId = 0;
        var productServiceUrl = versionInfo.ProductUrl;
        vm.message = '';
        vm.loading = true;
        vm.showClearAll = false;
        vm.filterAttributes;
        vm.products;
        vm.pageNumber = 1;
        vm.pageSize = 10;
        vm.totalCount = 0;
        vm.totalPages = 0;


        vm.init = function (productTypeId) {
            vm.productTypeId = productTypeId;
            vm.loadFilterAttributes();
        };

        vm.clearAttribute = function(attribute) {
            for (var i = 0; i < attribute.attributeValues.length; i++) {
                attribute.attributeValues[i].filter = false;
            }
        };

        vm.clearAll = function() {
            for (var i = 0; i < vm.filterAttributes.attributes.length; i++) {
                vm.clearAttribute(vm.filterAttributes.attributes[i]);
            }
        };

        vm.pageChange = function (pageNumber, pageSize) {
            vm.pageNumber = pageNumber;
            vm.pageSize = pageSize;
            vm.filterProducts();
        };

        vm.filterProducts = function () {
            vm.loading = true;
            vm.message = '';
            vm.showClearAll = false;
            if (vm.filterAttributes && vm.filterAttributes.attributes) {
                var filterParameters = [];
                for (var i = 0; i < vm.filterAttributes.attributes.length; i++) {
                    if (vm.filterAttributes.attributes[i].attributeValues) {
                        for (var x = 0; x < vm.filterAttributes.attributes[i].attributeValues.length; x++) {
                            if (vm.filterAttributes.attributes[i].attributeValues[x].filter === true) {
                                vm.showClearAll = true;
                                filterParameters.push({
                                    ProductTypeAttributeId: vm.filterAttributes.attributes[i].productTypeAttributeId,
                                    PossibleValueId: vm.filterAttributes.attributes[i].attributeValues[x].possibleValueId,
                                    ValueText: vm.filterAttributes.attributes[i].attributeValues[x].valueText
                                });
                            }
                        }
                    }
                }
                var url = productServiceUrl +
                    'v1/productType/' +
                    vm.productTypeId +
                    '/products?pageSize=' +
                    vm.pageSize +
                    '&pageNumber=' +
                    vm.pageNumber;
                for (var y = 0; y < filterParameters.length; y++) {
                    url = url +
                        '&filter={0}'.format(filterParameters[y].ProductTypeAttributeId +
                            '~' +
                            filterParameters[y].PossibleValueId +
                            '~' +
                            filterParameters[y].ValueText);
                }
                $http({
                    method: 'GET',
                    url: url,
                    headers: { 'Content-Type': 'application/json; charset=utf-8' }
                }).success(function(data, status, headers) {
                    var pageString = headers('x-pagination');
                    if (pageString) {
                        var page = JSON.parse(pageString);
                        vm.pageNumber = page.currentPage;
                        vm.pageSize = page.pageSize;
                        vm.totalCount = page.totalCount;
                        vm.totalPages = page.totalPages;
                    }
                    vm.products = data;
                    vm.loading = false;
                }).error(function(data, status, headers, config) {
                    console.log("error retrieving products");
                    vm.message = "Error retrieving products";
                    vm.loading = false;
                });
            } else {
                console.log("zero filter attributes");
                vm.message = "Zero filter attributes";
                vm.loading = false;
            }
        };

        vm.loadFilterAttributes = function () {
            
            $http({
                    method: 'GET',
                    url: productServiceUrl + 'v1/producttype/' + vm.productTypeId,
                    headers: { 'Accept': 'application/json;odata=verbose;charset=utf-8' }
                }).
                success(function (data) {
                    vm.filterAttributes = data;
                    vm.filterProducts();
                }).
                error(function (data, status, headers, config) {
                    console.log("error retrieving filter attributes");
                });
        };
    }
})();