//D:\ApplicationDevelopmentHillyardGit\Net\B2B\B2BC\Scripts\Custom\directive\paging\pagingCtrl.js
(function () {
    'use strict';
    angular
        .module('HillyardAngularApps')
        .controller('Paging', PagingCtrl);

    PagingCtrl.$inject = ['$scope'];

    function PagingCtrl($scope) {
        var vm = this;
        vm.pageSizeList = [10, 50, 100];
        vm.pageNumberList = [];

        vm.buildPageNumberList = function () {
            vm.pageNumberList = [];
            var start = vm.pageNumber > 5 ? vm.pageNumber - 5 : 1;
            if (start > vm.totalPages - 9) {
                start = vm.totalPages - 9 > 1 ? vm.totalPages - 9 : 1;
            }
            var end = (start + 9 < vm.totalPages) ? start + 9 : vm.totalPages;
            for (var i = start; i <= end; i++) {
                vm.pageNumberList.push(i);
            }
        };

        $scope.$watch('vm.totalPages', function (newItem, oldItem) {
            if (vm.totalPages && vm.pageNumber && vm.totalPages > 0 && vm.pageNumber > 0) {
                vm.buildPageNumberList();
            }
        });

        $scope.$watch('vm.pageNumber', function (newItem, oldItem) {
            if (vm.totalPages && vm.pageNumber && vm.totalPages > 0 && vm.pageNumber > 0) {
                vm.buildPageNumberList();
            }
        });

    };
})();