//D:\ApplicationDevelopmentHillyardGit\Net\B2B\B2BC\Scripts\Custom\services\UtilsService.js
hillyardAngularApps.factory('utilsService', 
    function () {
    return {
        isNumeric: function (value) {
            return value != undefined && !isNaN(parseFloat(value)) && isFinite(value);
        },

        generateRandomString: function () {
            var d = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        },

    };
});