//D:\ApplicationDevelopmentHillyardGit\Net\B2B\B2BC\Scripts\Custom\controllers\AccountActivityController.js
$(function () {
    $(".dateInput").datepicker({});
});

(function () {
    'use strict';
    angular.module('HillyardAngularApps').controller('AccountActivityCtrl', ['$rootScope', '$scope', '$http', '$window', 'moment', 'accountActivityCookieService', AccountActivityCtrl]);

    function AccountActivityCtrl($rootScope, $scope, $http, $window, moment, accountActivityCookieService) {
        var vm = this;
        vm.searchModel;
        vm.documents = undefined;
        vm.loading = false;
        vm.scrollPosition = 0;
        vm.initialPageLoad = true;
        vm.defaultDocumentType = '';
        vm.addTemplateRow = false;
        vm.isTemplateDocumentType = isTemplateType();
        vm.isAllDocumentType = isAllTypes();
        vm.showAddTemplate = false;
        vm.showDateRange = false;
        vm.showDateFields = true;
        vm.showFilterArea = true;

        vm.template = {
            name: '',
            type: { value: '', text: 'Select Template Type' },
            types: [
                { value: 'INV', text: 'Inventory', creatable: true },
                { value: 'ORD', text: 'Order', creatable: true },
                { value: 'REO', text: 'Reorder', creatable: false }
            ]
        }

        $window.onscroll = function () {
            vm.searchModel.ScrollPosition = document.body.scrollTop || document.documentElement.scrollTop || 0;
            $scope.$digest();
            accountActivityCookieService.createSearchParameterCookie(vm.searchModel);
        };

        vm.selectedShipToCustomerNumber = '';
        vm.customerList = [];
        vm.documentTypes = [
            { Value: '', Text: 'All Documents', SearchTypes: [] },
            { Value: 'O', Text: 'Orders', SearchTypes: [{ Value: '', Text: 'Select a Search Type' }, { Value: 'PURCHASE_ORDER', Text: 'PO Number' }, { Value: 'DOCUMENT_ID', Text: 'Document Number' }, { Value: 'MATERIAL', Text: 'Product Number' }] },
            { Value: 'Q', Text: 'Quotations', SearchTypes: [{ Value: '', Text: 'Select a Search Type' }, { Value: 'PURCHASE_ORDER', Text: 'PO Number' }, { Value: 'DOCUMENT_ID', Text: 'Quote Number' }, { Value: 'MATERIAL', Text: 'Product Number' }] },
            { Value: 'T', Text: 'Templates', SearchTypes: [{ Value: '', Text: 'Select a Search Type' }, { Value: 'PO_NUMBER_EXT', Text: 'PO Number' }, { Value: 'OBJECT_ID', Text: 'Document Number' }] },
            { Value: 'I', Text: 'Invoices', SearchTypes: [{ Value: '', Text: 'Select a Search Type' }, { Value: 'HEADNO_EXT', Text: 'Document Number' }, { Value: 'REFERENCE_NO', Text: 'Reference Number' }] }];

        vm.statusTypes = [{ Value: '', Text: 'All' }, { Value: 'O', Text: 'Open' }, { Value: 'C', Text: 'Completed' }];
        vm.dateTypes = [{ Value: 'CM', Text: 'Current Month' }, { Value: 'PM', Text: 'Previous Month' }, { Value: 'CW', Text: 'Current Week' }, { Value: 'PW', Text: 'Previous Week' }, { Value: 'YTD', Text: 'Year To Date' }, { Value: 'T', Text: 'Today' }, { Value: 'Y', Text: 'Yesterday' }, { Value: 'R', Text: 'Date Range' }];

        vm.init = function (documentType) {
            vm.defaultDocumentType = documentType;
            vm.loadSearchParameters();
            vm.loadCustomer();
        };

        vm.documentTypeChanged = function (item) {
            vm.searchModel.DocumentType = item
            if (item.SearchTypes.length > 0) {
                vm.searchModel.SearchType = item.SearchTypes[0];
            } else {
                vm.searchModel.SearchType = '';
            }
            vm.defaultDocumentType = item;
            if (vm.defaultDocumentType.Value == 'T' || vm.defaultDocumentType.Value == 'Q') {
                vm.showDateFields = false;
            } else {
                vm.showDateFields = true;
            }
        };

        vm.dateTypeClick = function (item) {
            var currentDate = moment();
            vm.searchModel.DateType = item;
            switch (item.Value) {
                case 'CM': // Current Month
                    vm.searchModel.StartDate = moment({ y: currentDate.year(), M: currentDate.month(), d: 1 }).format('MM/DD/YYYY');
                    vm.searchModel.EndDate = moment({ y: currentDate.year(), M: currentDate.month(), d: currentDate.date() }).format('MM/DD/YYYY');
                    vm.showDateRange = false;
                    break;
                case 'PM': // Previous Month
                    vm.searchModel.StartDate = moment({ y: currentDate.year(), M: currentDate.month(), d: 1 }).subtract('months', 1).format('MM/DD/YYYY');
                    vm.searchModel.EndDate = moment({ y: currentDate.year(), M: currentDate.month(), d: 1 }).subtract('days', 1).format('MM/DD/YYYY');
                    vm.showDateRange = false;
                    break;
                case 'CW': // Current Week
                    var tempDate = moment({ y: currentDate.year(), M: currentDate.month(), d: currentDate.date() });
                    vm.searchModel.StartDate = tempDate.subtract('days', tempDate.weekday()).format('MM/DD/YYYY');
                    vm.searchModel.EndDate = moment({ y: currentDate.year(), M: currentDate.month(), d: currentDate.date() }).format('MM/DD/YYYY');
                    vm.showDateRange = false;
                    break;
                case 'PW': // Previous Week
                    var tempDate = moment({ y: currentDate.year(), M: currentDate.month(), d: currentDate.date() });
                    tempDate.subtract('days', tempDate.weekday());
                    vm.searchModel.StartDate = tempDate.subtract('days', 7).format('MM/DD/YYYY');
                    vm.searchModel.EndDate = tempDate.add('days', 6).format('MM/DD/YYYY');
                    vm.showDateRange = false;
                    break;
                case 'YTD': // YTD
                    vm.searchModel.StartDate = moment({ y: currentDate.year(), M: 0, d: 1 }).format('MM/DD/YYYY');
                    vm.searchModel.EndDate = moment({ y: currentDate.year(), M: currentDate.month(), d: currentDate.date() }).format('MM/DD/YYYY');
                    vm.showDateRange = false;
                    break;
                case 'T': // Today
                    vm.searchModel.StartDate = moment({ y: currentDate.year(), M: currentDate.month(), d: currentDate.date() }).format('MM/DD/YYYY');
                    vm.searchModel.EndDate = moment({ y: currentDate.year(), M: currentDate.month(), d: currentDate.date() }).format('MM/DD/YYYY');
                    vm.showDateRange = false;
                    break;
                case 'Y': // Yesterday
                    vm.searchModel.StartDate = moment({ y: currentDate.year(), M: currentDate.month(), d: currentDate.date() }).subtract('days', 1).format('MM/DD/YYYY');
                    vm.searchModel.EndDate = moment({ y: currentDate.year(), M: currentDate.month(), d: currentDate.date() }).subtract('days', 1).format('MM/DD/YYYY');
                    vm.showDateRange = false;
                    break;
                case 'R': // Date Range
                    vm.searchModel.StartDate = moment({ y: currentDate.year(), M: currentDate.month(), d: currentDate.date() }).format('MM/DD/YYYY');
                    vm.searchModel.EndDate = moment({ y: currentDate.year(), M: currentDate.month(), d: currentDate.date() }).format('MM/DD/YYYY');
                    vm.showDateRange = true;
                    break;
            };
        };

        vm.search = function () {
            accountActivityCookieService.createSearchParameterCookie(vm.searchModel);
            vm.documents = undefined;
            vm.loading = true;
            var searchType = vm.searchModel.SearchType == undefined || vm.searchModel.SearchType.Value == undefined ? '' : vm.searchModel.SearchType.Value;
            var searchCriteria = vm.searchModel.SearchType == undefined || vm.searchModel.SearchType.Value == undefined || vm.searchModel.SearchCriteria == undefined ? '' : vm.searchModel.SearchCriteria;
            vm.defaultDocumentType = vm.searchModel.DocumentType.Value;
            vm.isTemplateDocumentType = isTemplateType();
            vm.isQuoteDocumentType = isQuoteType();
            var timeDiff = Math.abs(new Date(vm.searchModel.EndDate) - new Date(vm.searchModel.StartDate));
            var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

            if (diffDays <= 366) {
                $http.get('/MyAccountActivity/SearchDocuments/',
                    {
                        params:
                        {
                            DocumentType: vm.searchModel.DocumentType.Value,
                            StatusType: vm.searchModel.StatusType.Value,
                            ShipToCustomerNumber: vm.searchModel.SelectedShipToCustomer.CustomerNumber,
                            DateType: vm.searchModel.DateType.Value,
                            StartDate: vm.searchModel.StartDate,
                            EndDate: vm.searchModel.EndDate,
                            SearchType: searchType,
                            SearchCriteria: searchCriteria,
                            ScrollPosition: 0,
                            SortColumn: '',
                            SortDirection: '',
                            NoCache: Date.now()
                        }
                    }).success(function (data, status, headers, config) {
                        vm.documents = data.Documents;
                        vm.loading = false;
                        if (vm.initialPageLoad) {
                            vm.initialPageLoad = false;
                            $window.setTimeout(function () {
                                $window.scroll(0, vm.searchModel.ScrollPosition);
                            },
                                500);
                        }
                    }).error(function (data, status, headers, config) {
                        vm.loading = false;
                        alert('error');
                    });
            } else {
                alert("Date range must not exceed 1 year.");
            }

        };

        vm.loadCustomer = function () {
            $http({
                method: 'GET',
                url: '/MyAccountActivity/RetrieveShipToCustomers/',
                headers: { 'Accept': 'application/json;odata=verbose;charset=utf-8' }
            }).
                success(function (data, status, headers, config) {
                    vm.customerList = data.Customers;
                    vm.search();
                }).
                error(function (data, status, headers, config) {
                    alert('error');
                });
        };

        vm.loadSearchParameters = function () {
            var cookie = accountActivityCookieService.readSearchParameterCookie();

            if (cookie == undefined || vm.defaultDocumentType.length > 0) {
                var selectedDocumentType = _.find(vm.documentTypes, function (type) {
                    return (vm.defaultDocumentType.length == 0 && type.Value == "") || (vm.defaultDocumentType.length > 0 && type.Value == vm.defaultDocumentType);
                });
                var selectedStatusType = _.find(vm.statusTypes, function (type) { return type.Value == ""; })
                var selectedSearchType = _.find(selectedDocumentType.SearchTypes, function (type) { return type.Value == ""; });
                var selectedDateType = _.find(vm.dateTypes, function (type) { return type.Value == "CM"; });
                vm.defaultDocumentType = '';

                vm.searchModel =
                    {
                        DocumentType: selectedDocumentType,
                        StatusType: selectedStatusType,
                        SelectedShipToCustomer: { CustomerNumber: "", CustomerName: "All Customers" },
                        DateType: selectedDateType,
                        StartDate: '',
                        EndDate: '',
                        SearchType: selectedSearchType,
                        SearchCriteria: '',
                        ScrollPosition: 0,
                        SortColumn: 'DocumentDate',
                        SortDirection: 'false'
                    };
                vm.dateTypeClick(selectedDateType);
                accountActivityCookieService.createSearchParameterCookie(vm.searchModel);
            } else {
                vm.searchModel = cookie;
                var dateType = vm.searchModel.DateType.Value;
                if (dateType == 'CM' || dateType == 'CW' || dateType == 'YTD' || dateType == 'T') {
                    var currentDate = moment();  //Set EndDate to today if dateType is current month, week, year or day
                    vm.searchModel.EndDate = moment({ y: currentDate.year(), M: currentDate.month(), d: currentDate.date() }).format('MM/DD/YYYY');
                }
            }
        };

        vm.sortTable = function (sortColumn) {
            vm.searchModel.SortDirection = (vm.searchModel.SortColumn == sortColumn) ? !vm.searchModel.SortDirection : false;
            vm.searchModel.SortColumn = sortColumn;
            accountActivityCookieService.createSearchParameterCookie(vm.searchModel);
        };

        vm.toggleTemplateRow = function () {
            vm.addTemplateRow = !vm.addTemplateRow;
        };

        vm.showAddTemplateArea = function () {
            vm.showAddTemplate = !vm.showAddTemplate;
        }
        vm.dateHelper = ToJavaScriptDate;
        //////////////////// 

        function isTemplateType() {
            return vm.defaultDocumentType === 'T';
        }
        function isAllTypes() {
            return vm.defaultDocumentType === '';
        }
        function isQuoteType() {
            return vm.defaultDocumentType === 'Q';
        }
        function isShowPrices() {
            return vm.documents(1) == true;
        }
        function ToJavaScriptDate(value) {
            var pattern = /Date\(([^)]+)\)/;
            var results = pattern.exec(value);
            return new Date(parseFloat(results[1]));
        }
    }
})();