//D:\ApplicationDevelopmentHillyardGit\Net\B2B\B2BC\Scripts\Custom\filters\filters.js
'use strict';

hillyardAngularApps.filter('breadCrumbMobileFilter', function () {
    return function (data) {
        var filtered = [];
        for (var i = 0; i < data.length; i++) {
            if (i === 0 || i === 1 || i === data.length - 1) {
                filtered.push(data[i]);
            }
        }
        return filtered;
    }
});