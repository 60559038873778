//D:\ApplicationDevelopmentHillyardGit\Net\B2B\B2BC\Scripts\Custom\directive\paging\pagingDirective.js
(function () {
    'use strict';

    angular
        .module('HillyardAngularApps')
        .directive('paging', HillyardPaging);

    function HillyardPaging() {
        return {
            restricts: 'E',
            scope: {
                totalCount: '=',
                totalPages: '=',
                pageNumber: '=',
                pageSize: '=',
                pageChangeEvent: '&'
            },
            controller: 'Paging',
            controllerAs: 'vm',
            bindToController: true,
            templateUrl: '/scripts/custom/directive/paging/paging.html'
        };
    };

})();