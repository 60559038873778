//D:\ApplicationDevelopmentHillyardGit\Net\B2B\B2BC\Scripts\Custom\services\LocationCookieService.js
hillyardAngularApps.factory('locationCookieService',['$log','cookieService',
    function ($log, cookieService) {
    var locationCookieName = 'defaultDistributor';
    return {
        createLocationCookie: function (distributorId, shop, distributorName, distributorWebSite, distributorType) {
            var distributorShortName = distributorName.replace('HILLYARD / ', '');
            var cookieValue = {
                "Id": distributorId,
                "Shop": shop,
                "Name": distributorShortName,
                "LongName":distributorName,
                "Site": distributorWebSite,
                "Type": distributorType
            };
            cookieService.createCookie(locationCookieName, JSON.stringify(cookieValue), 365);  // For now this createCookie function is in masterPage.js            

            // Change distributor name at the top of the screen.
            $(".defaultLocationLabel").text('My location ' + distributorName + ' (change)');
        },
        readLocationCookie: function () {
            return cookieService.readCookie(locationCookieName);
        },
        readHillyardShop: function () {
            try {
                var cookie = cookieService.readCookie(locationCookieName);
                if (cookie != undefined) {
                    var cookieData = JSON.parse(cookie);
                    if (cookieData != undefined && cookieData.Shop != undefined && cookieData.Type == 'C')
                        return cookieData.Shop;
                }
            } catch (e) {
                $log.error(e);
            }
            return '';
        },
        readHillyardDistributorId: function () {
            try {
                var cookie = cookieService.readCookie(locationCookieName);
                if (cookie != undefined) {
                    var cookieData = JSON.parse(cookie);
                    if (cookieData != undefined && cookieData.Id != undefined && cookieData.Type == 'C')
                        return cookieData.Id;
                }
            } catch (e) {
                $log.error(e);
            }
            return 0;
        },
        readDistributorId: function () {
            try {
                var cookie = cookieService.readCookie(locationCookieName);
                if (cookie != undefined) {
                    var cookieData = JSON.parse(cookie);
                    if (cookieData != undefined && cookieData.Id != undefined)
                        return cookieData.Id;
                }
            } catch (e) {
                $log.error(e);
            }
            return 0;
        },
        readDistributorName: function() {
            try {
                var cookie = cookieService.readCookie(locationCookieName);
                if (cookie != undefined) {
                    var cookieData = JSON.parse(cookie);
                    if (cookieData != undefined && cookieData.Name != undefined)
                        return cookieData.Name;
                }
            } catch (e) {
                $log.error(e);
            }
            return '';
        },
        readDistributorLongName: function () {
            try {
                var cookie = cookieService.readCookie(locationCookieName);
                if (cookie != undefined) {
                    var cookieData = JSON.parse(cookie);
                    if (cookieData != undefined && cookieData.LongName != undefined)
                        return cookieData.LongName;
                }
            } catch (e) {
                $log.error(e);
            }
            return '';
        },
        readHillyardDistributorName: function() {
            try {
                var cookie = cookieService.readCookie(locationCookieName);
                if (cookie != undefined) {
                    var cookieData = JSON.parse(cookie);
                    if (cookieData != undefined && cookieData.Name != undefined && cookieData.Type == 'C')
                        return cookieData.Name;
                }
            } catch (e) {
                $log.error(e);
            }
            return '';
        },
        eraseDistributorCookie: function () {
            cookieService.eraseCookie(locationCookieName);
            $(".defaultLocationLabel").text('Select your location');
        }

    }
}]);