//D:\ApplicationDevelopmentHillyardGit\Net\B2B\B2BC\Scripts\Custom\controllers\SearchController.js
$('body').on('click.collapse-next.data-api', '[data-toggle=collapse-next]', function () {
    var $target = $(this).parent().parent().next('.accordion-body');
    $target.collapse('toggle');
});

hillyardAngularApps.controller('SearchCtrl',['$scope', '$q', '$log', '$window', '$sce', 'searchProviderService', 'httpService',
    function ($scope, $q, $log, $window, $sce, searchProviderService, httpService) {
    $scope.buttonClass = 'glyphicon glyphicon-search white';
    $scope.searchProvider = searchProviderService.getCurrentProvider();
    $scope.searchMessage = '';
    $scope.searching = false;

    $scope.searchKeyPress = function (ev) {
        if (ev.which == 13) {
            $scope.search(false);
            ev.preventDefault();
        } else {
            $scope.search(true);
        }
    };

    $scope.searchClick = function (searchValue) {
        _.each($('.searchQuery'), function (value) {
            if ($(value).val().length > 0) {
                searchQuery = $(value).val(searchValue);
            }
        });
        $scope.search(false);
    };

    $scope.search = function (searchAutoComplete) {
        if (!$scope.searching) {
            $scope.searchMessage = '';
            var searchQuery = '';
            _.each($('.searchQuery'), function (value) {
                if ($(value).val().length > 0) {
                    searchQuery = $(value).val();
                }
            });
            if (!searchAutoComplete) {
                $('.searchResultsContainer').show('blind', {}, 500);
                $scope.searching = true;
                $scope.buttonClass = 'loading';
            }
            if (searchQuery.length > 1) {
                var searchResults = false;
                var pathName = $window.location.pathname.toLowerCase();
                $scope.searchProvider.SearchResults = [];
                var queryPromises = [];
                var number = 0;
                _.each($scope.searchProvider.SearchEngines, function (data) {
                    if (data.AutoComplete === searchAutoComplete) {
                        number += 1;
                        var parameters = '';
                        if (data.CustomSearchParameters == undefined) {
                            parameters = $scope.searchProvider.SearchParameters();
                        } else {
                            parameters = data.CustomSearchParameters();
                        }
                        var provider = _.find(data.DefaultPages, function (page) {
                            return pathName.indexOf(page) > -1;
                        });
                        var searchObject = {
                            Name: data.Name,
                            VisibleIfNoResults: data.VisibleIfNoResults,
                            Visible: true,
                            ResultsForm: data.ResultsForm,
                            Results: [],
                            Message: 'Loading...',
                            Number: number,
                            AccordionOpen: provider != undefined || data.OpenIfResults
                        };
                        $scope.searchProvider.SearchResults.push(searchObject);

                        var queryPromise = data.SearchFunction(data.SearchUrl + parameters).then(
                            function (results) {
                                if (results.length > 0) {
                                    searchResults = true;
                                }
                                searchObject.Results = results;
                                searchObject.Visible = searchObject.VisibleIfNoResults || results.length > 0;
                                searchObject.Message = results.length;
                                if (results.length == 0) {
                                    searchObject.AccordionOpen = false;
                                }
                            },
                            function (results) {
                                $log.error("Error searching {0}".format(data.Name));
                                searchObject.Visible = false;
                                searchObject.Results = [];
                                searchObject.Message = "Error";
                            });
                        queryPromises.push(queryPromise);
                    }
                });
                $q.all(queryPromises)
                    .then(function () {
                        $scope.buttonClass = 'glyphicon glyphicon-search white';
                        $scope.searching = false;
                        if ((searchAutoComplete && searchResults) || !searchAutoComplete) {
                            $('.searchResultsContainer').show('blind', {}, 500);
                        } else if (searchAutoComplete && !searchResults) {
                            $('.searchResultsContainer').hide('blind', {}, 500);
                        }
                    },
                    function () {
                        $scope.searching = false;
                        $scope.buttonClass = 'glyphicon glyphicon-search white';
                    });
            } else {
                if (!searchAutoComplete) {
                    $('.searchResultsContainer').show('blind', {}, 500);
                    $scope.searchMessage = 'Enter at least 2 characters to search';
                }
            }
        }
    };

    $scope.searchQuery = function (searchQuery) {

    };

    $(document).on('click', '.searchResultLink', function () {
        $scope.clearSearch();
    });

    $scope.clearSearch = function () {
        $('.searchResultsContainer').hide('blind', {}, 500);
    };

    $scope.VisibleSearchCount = function () {
        if ($scope.searchMessage.length === 0) {
            for (var i = 0; i < $scope.searchProvider.SearchResults.length; i++) {
                if ($scope.searchProvider.SearchResults[i].Visible) {
                    return false;
                }
            }
            return true;
        }
        return false;
    };

    $scope.showOnMap = function (latitude, longitude) {
        var pathname = $window.location.pathname;
        if (pathname.indexOf('HillyardLocations.aspx') == -1) {
            window.location.href = "/_catalogs/masterpage/hillyardmasterv1/hillyardapps/Locations.aspx?latitude=" + latitude + "&longitude=" + longitude;
        } else {
            var center = new google.maps.LatLng(latitude, longitude);
            map.setCenter(center);
            map.setZoom(8);
            if (marker != null) {
                marker.setMap(null);
            }
            marker = new google.maps.Marker({
                position: center,
                map: map,
                title: 'marker'
            });
        }
    };

    $scope.trustedHtml = function (htmlCode) {
        return $sce.trustAsHtml(htmlCode);
    };

    $scope.getTarget = function (url) {
        if (url.indexOf('.pdf') > -1 || url.indexOf('hillyard.com') == -1) {
            return '_blank';
        } else {
            return '_self';
        }
    };
}]);